<template lang="html">
    <div>
        <b-navbar type="light" variant="dark" fixed="top" style="position: fixed;height: 50px;">
            <img src="../../assets/img/logo.png" class="rounded mx-auto d-block" style="width: 50%;">
        </b-navbar>
        <div class="container-fluid" style="margin-top: 16%;">
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
            <div style="margin-bottom: 100px;" v-show="showReturn">
                <b-row class="mt-2" v-for="item in returnItems">
                    <b-col>
                        <b-card @click="returnInfo(item)">
                            <b-row class="justify-content-md-center">
                                <b-col>
                                    <h3>{{item.name}} ( {{item.location}} )</h3>
                                </b-col>
                            </b-row>
                            <hr class="mt-2 mb-2">
                            <b-row class="justify-content-md-center">
                                <b-col cols="3">
                                    <p>{{item.year}}</p>
                                    <p class="return-day">{{item.day}}</p>
                                    <p>{{item.month}}</p>
                                </b-col>
                                <b-col cols="5">
                                    <p>{{item.saller}}</p>
                                    <p>{{item.phone}}</p>
                                    <p>Qty : {{item.count}} </p>
                                </b-col>
                                <b-col cols="4">
                                    <p>-</p>
                                    <p class="text-capitalize">{{item.status}}</p>
                                    <p>-</p>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
            <div class="row status-info" v-show="showInfo">
                <div class="col-md-12 ">
                    <table class="table table-cart" style="margin-top: 10%;">
                        <tr>
                            <th style="width: 100px;">Name</th>
                            <th style="width: 50;">
                                Qty
                            </th>
                            <th style="width: 50;">
                                Distri Damage
                            </th>
                            <th style="width: 50;">
                                Transport Damage
                            </th>
                        </tr>
                        <tr v-for="(info, index) in returnItemInfo">
                            <td style="width: 100px;">{{info.item.name}}</td>
                            <td style="width: 50;">
                                {{info.quantity}}
                            </td>
                            <td style="width: 50;">
                                {{info.distri_damage_quantity}}
                            </td>
                            <td style="width: 50;">
                                {{info.damage_quantity}}
                            </td>
                        </tr>
                    </table>
                    <textarea class="form-control" disabled style="background-color: #fff;">{{remark}}</textarea>
                    <a class="btn btn-success mt-2 text-white" style="width: 100%;"
                            @click="closeReturnInfo()">Close</a>
                </div>
            </div>
            <router-link class="float" to="return-create">
                <i class="fa fa-plus my-float" style="padding: 37%;"></i>
            </router-link>
        </div>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'item',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                showReturn: true,
                showInfo: false,
                returnItems: [],
                returnItemInfo: [],
                remark: ""
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                returnListAction: 'returnListAction'
            }),
            async orderList() {
                this.isLoading = true;
                await this.returnListAction().then(res => {
                    if (res.status == "success") {
                        this.returnItems = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            returnInfo(item) {
                this.showReturn = false
                this.showInfo = true
                this.returnItemInfo = item.info
                this.remark = item.distri_remark
            },
            closeReturnInfo() {
                this.showReturn = true
                this.showInfo = false
            },
        },
        async mounted() {
            this.orderList()
        }
    }
</script>

<style scoped lang="scss">
    td {
        padding: 5px;
    }
    .float {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 70px;
        right: 3%;
        background-color: #0C9;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .float-filter {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 90px;
        right: 2%;
        background-color: #318be3;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .return-day {
        font-size: larger;
        font-weight: bold;
        font-style: italic;
    }

    .status-info {
        width: 100%;
        height: 100vh;
        background: hsl(210 11% 92% / 1);
        z-index: 9999;
        position: absolute;
        top: 52px;
    }
</style>